import { styled } from '@mui/material/styles'

const StyledAnchor = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

export default StyledAnchor
