import QueryString from 'qs'

const paths = {
  opportunities({ salesforceId } = {}, tab = '') {
    const basePath = '/opportunities'
    if (!salesforceId) {
      return basePath
    }
    let url = `${basePath}/${salesforceId}`
    if (tab) {
      url += `/${tab}`
    }
    return url
  },
  campaignByInvestor({ salesforceId }, { permalink }, { _key }) {
    return `/opportunities/${salesforceId}/campaign/by-investor/${permalink}/${_key}`
  },
  campaignByResearchList(salesforceId, { _key }, contacts) {
    return `/opportunities/${salesforceId}/campaign/by-research-list/${_key}?${QueryString.stringify(
      {
        targets: contacts,
      },
    )}`
  },
  companiesShortcuts() {
    return '/companies/shortcuts'
  },
  insights() {
    return '/insights'
  },
  latestFundingRounds() {
    return '/insights/latest-funding-rounds'
  },
  totalFundingByIndustry() {
    return '/insights/total-funding-by-industry'
  },
  fundingByIndustry() {
    return '/insights/funding-by-industry'
  },
  companies({ permalink } = {}, tab = '') {
    const basePath = '/companies'
    if (!permalink) {
      return basePath
    }
    let url = `${basePath}/${permalink}`
    if (tab) {
      url += `/${tab}`
    }
    return url
  },
  investors({ permalink } = {}, tab = '') {
    const basePath = '/investors'
    if (!permalink) {
      return basePath
    }
    let url = `${basePath}/${permalink}`
    if (tab) {
      url += `/${tab}`
    }
    return url
  },
  investorsShortcuts() {
    return '/investors/shortcuts'
  },
}

export default paths
