import axios from 'axios'
import { debounce, trim } from 'lodash'
import SearchIcon from '@mui/icons-material/Search'
import { useRef } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import api from 'utils/api'
import { useSearchProvider } from 'contexts/Search'

const { CancelToken } = axios
let cancel

export default function SearchField({ label, placeholder, ...materialProps }) {
  const { searchValue, setSearchValue, setLoading, setResults } = useSearchProvider()
  const searchOrganizations = useRef(
    debounce(
      (searchQuery) => {
        api
          .get(`/autocomplete-organization-name/${trim(searchQuery)}`, {
            cancelToken: new CancelToken((c) => {
              cancel = c
            }),
          })
          .then(({ data }) => {
            setResults(data)
          })
          .finally(() => {
            setLoading(false)
          })
      },
      500,
      { leading: false, trailing: true, maxWait: 2000 },
    ),
  ).current

  const handleChange = (event) => {
    if (typeof cancel === 'function') cancel()
    const { value: eventValue } = event.target
    setSearchValue(eventValue)
    if (eventValue.length > 2) {
      setLoading(true)
      searchOrganizations(eventValue)
    } else {
      setResults({})
      setLoading(false)
      searchOrganizations.cancel()
    }
  }
  return (
    <TextField
      {...materialProps}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      value={searchValue}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      inputProps={{
        // Class for allowing hotjar to gather the input data.
        // This is not sensitive data and is done for gathering analytica data.
        className: 'data-hj-allow',
      }}
    />
  )
}
