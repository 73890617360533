import React from 'react'
import { Container, Box } from '@mui/material'
import getConfig from 'next/config'

import Head from './Head'
import Footer from './Footer'
import Navbar from './Navbar'

type Props = {
  title: string
  noNavbar?: boolean
  children: React.ReactNode
}

export default function Layout({ title, noNavbar, children }: Props) {
  const { publicRuntimeConfig } = getConfig()

  return (
    <>
      <Head title={title} />
      {!noNavbar && <Navbar />}
      <Container maxWidth={false}>
        <Box pt={16}>{children}</Box>
      </Container>
      <Footer buildId={publicRuntimeConfig.buildId} />
    </>
  )
}
