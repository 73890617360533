import React from 'react'
import { Slide, useScrollTrigger } from '@mui/material'

export default function HideOnScroll(props) {
  const trigger = useScrollTrigger({ threshold: 1000 })
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  )
}
