import Link from 'next/link'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React, { forwardRef, useContext, useState, memo } from 'react'
import {
  Grid,
  Avatar,
  Box,
  AppBar,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Button,
  Hidden,
  Toolbar,
  ButtonBase,
} from '@mui/material'
import dynamic from 'next/dynamic'
import HideOnScroll from 'components/hocs/HideOnScroll'
import DropdownMenu from 'components/DropdownMenu'
import paths from 'utils/paths'
import AppContext from 'contexts/App'

import SearchDrawer from './SearchDrawer'
import MobileDrawer from './MobileDrawer'
import Image from 'next/image'
import LinkAnchor from 'components/LinkAnchor'

const WhatsNewButton = dynamic(() => import('./WhatsNewButton'), { ssr: false })

const tabStyles = (theme) => ({ padding: theme.spacing(2, 8), height: '52px' })

export default memo(() => {
  const router = useRouter()
  const { publicRuntimeConfig } = getConfig()

  const currentTabName = router.pathname.split('/')[1]
  const tabIndexes = { opportunities: 0, companies: 1, investors: 2, insights: 3 }

  const [{ user, logout }] = useContext(AppContext)

  const [currentTabIndex, setCurrentTabIndex] = useState(tabIndexes[currentTabName])
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null)

  return (
    <HideOnScroll>
      <AppBar color="inherit">
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box width="150px" display="flex" alignItems="center" component={ButtonBase}>
                <LinkAnchor href={paths.opportunities()} passHref>
                  <Image
                    src="/logo-scenic-amp.svg"
                    alt="Scenic Logo"
                    layout="fixed"
                    width="150px"
                    height="18px"
                  />
                </LinkAnchor>
              </Box>
            </Grid>
            {publicRuntimeConfig.env !== 'development' ? <WhatsNewButton /> : null}
            <Hidden smDown implementation="css">
              <Box displayPrint="none">
                <Grid item>
                  <Tabs
                    value={currentTabIndex}
                    onChange={(event, newValue) => setCurrentTabIndex(newValue)}
                    indicatorColor="primary"
                    textColor="primary">
                    <Tab
                      sx={tabStyles}
                      component={forwardRef((props, ref) => (
                        <Button>
                          <Link href={paths.opportunities()}>
                            <a ref={ref} {...props}>
                              <Box fontWeight={600}>Opportunities</Box>
                            </a>
                          </Link>
                        </Button>
                      ))}
                    />
                    <Tab
                      sx={tabStyles}
                      component={forwardRef((props, ref) => (
                        <DropdownMenu
                          ref={ref}
                          menuText="Companies"
                          items={[
                            {
                              label: 'All Companies',
                              url: paths.companies(),
                            },
                            {
                              label: 'Shortcuts',
                              url: paths.companiesShortcuts(),
                            },
                          ]}
                          {...props}
                        />
                      ))}
                    />
                    <Tab
                      sx={tabStyles}
                      component={forwardRef((props, ref) => (
                        <DropdownMenu
                          ref={ref}
                          menuText="Investors"
                          items={[
                            {
                              label: 'All Investors',
                              url: paths.investors(),
                            },
                            {
                              label: 'Shortcuts',
                              url: paths.investorsShortcuts(),
                            },
                          ]}
                          {...props}
                        />
                      ))}
                    />
                    <Tab
                      sx={tabStyles}
                      component={forwardRef((props, ref) => (
                        <DropdownMenu
                          ref={ref}
                          menuText="Insights"
                          items={[
                            {
                              label: 'Dashboard',
                              url: paths.insights(),
                            },
                            {
                              label: 'Recent Raises',
                              url: paths.latestFundingRounds(),
                            },
                            {
                              label: 'Total Funding by Industries',
                              url: paths.totalFundingByIndustry(),
                            },
                            {
                              label: 'Funding by Industries',
                              url: paths.fundingByIndustry(),
                            },
                          ]}
                          {...props}
                        />
                      ))}
                    />
                  </Tabs>
                </Grid>
              </Box>
            </Hidden>

            <Hidden mdDown implementation="css">
              <Box displayPrint="none">
                <Grid item>
                  {router.pathname === '/opportunities' ? null : <SearchDrawer />}
                  <Button
                    aria-controls="user-dropdown-menu"
                    aria-haspopup="true"
                    sx={{ color: 'inherit' }}
                    onClick={(event) => setProfileMenuAnchorEl(event.currentTarget)}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="row">
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        alt={user.name}
                        src={`/api/users/${user.id}/avatar?token=${user.token}`}
                      />
                      <Box ml={1}>{user.name}</Box>
                    </Box>
                  </Button>

                  <Menu
                    id="user-dropdown-menu"
                    anchorEl={profileMenuAnchorEl}
                    keepMounted
                    open={Boolean(profileMenuAnchorEl)}
                    getcontentanchorel={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={() => setProfileMenuAnchorEl(null)}>
                    <MenuItem
                      onClick={() => {
                        setProfileMenuAnchorEl(null)
                        logout()
                      }}>
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              </Box>
            </Hidden>

            <Hidden mdUp implementation="css">
              <Box displayPrint="none">
                <MobileDrawer />
              </Box>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
})
