import { SxProps } from '@mui/system'
import Link from 'next/link'
import { ReactNode } from 'react'
import StyledAnchor from './StyledAnchor'

type LinkAnchorProps = {
  href: string
  children: ReactNode
  sx?: SxProps
  target?: string
}

const LinkAnchor = ({ href, children, sx, target }: LinkAnchorProps) => {
  // External link
  if (target) {
    return (
      <StyledAnchor href={href} target={target} rel="noopener noreferrer" sx={sx}>
        {children}
      </StyledAnchor>
    )
  }
  // Internal link
  return (
    <Link href={href} passHref>
      <StyledAnchor sx={sx}>{children}</StyledAnchor>
    </Link>
  )
}
export default LinkAnchor
