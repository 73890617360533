import { createContext, useContext, useState } from 'react'

export const useSearch = () => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState({})
  const [searchValue, setSearchValue] = useState('')
  return {
    loading,
    setLoading,
    results,
    setResults,
    searchValue,
    setSearchValue,
  }
}

const Context = createContext(null)

export default function SearchProvider({ children }) {
  return <Context.Provider value={useSearch()}>{children}</Context.Provider>
}

export const useSearchProvider = () => {
  return useContext(Context)
}
