import { size, noop } from 'lodash'
import { Box, CircularProgress, Typography, List, ListSubheader } from '@mui/material'
import paths from 'utils/paths'
import SearchResultItem from 'components/SearchCompanies/SearchResultItem'
import { useSearchProvider } from 'contexts/Search'

const EmptyResultsInformation = ({ primaryHeader, secondaryHeader }) => {
  return (
    <Box textAlign="center">
      <Typography variant="h5" gutterBottom color="primary">
        {primaryHeader}
      </Typography>
      <Typography variant="h5" gutterBottom>
        {secondaryHeader}
      </Typography>
    </Box>
  )
}

export default function SearchResultList({ onClick = noop }) {
  const { searchValue, results, loading } = useSearchProvider()
  return (
    <Box height="100%">
      {searchValue.length < 3 ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <EmptyResultsInformation
            primaryHeader="You're searching for organizations and opportunities."
            secondaryHeader="Type more than three characters to see results."
          />
        </Box>
      ) : null}
      {loading || (size(results.organizations) === 0 && size(results.opportunities) === 0) ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          {loading ? (
            <CircularProgress />
          ) : (
            <EmptyResultsInformation
              primaryHeader="No results found!"
              secondaryHeader="Use the field above to search for companies"
            />
          )}
        </Box>
      ) : (
        <List disablePadding>
          <ListSubheader disableSticky>
            Opportunities {`- ${size(results.opportunities)}`}
          </ListSubheader>
          {results.opportunities.map((option) => {
            return (
              <SearchResultItem
                key={option.salesforceId}
                option={option}
                searchValue={searchValue}
                linkProps={{ href: paths.opportunities(option, 'matches') }}
                onClick={onClick}
              />
            )
          })}
          <ListSubheader disableSticky>Companies</ListSubheader>
          {results.organizations.map((option) => {
            const linkProps =
              option.primaryRole === 'Company'
                ? { href: paths.companies(option, 'profile') }
                : { href: paths.investors(option, 'profile') }
            return (
              <SearchResultItem
                key={option.permalink}
                option={option}
                searchValue={searchValue}
                linkProps={linkProps}
                onClick={onClick}
              />
            )
          })}
        </List>
      )}
    </Box>
  )
}
