import Link from 'next/link'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import BusinessIcon from '@mui/icons-material/Business'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useContext, useState } from 'react'
import {
  List,
  Avatar,
  Box,
  Typography,
  Link as MaterialLink,
  Button,
  Divider,
  SwipeableDrawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import paths from 'utils/paths'

import Icon from 'components/icon'
import AppContext from 'contexts/App'
import SearchDrawer from './SearchDrawer'

export default function MobileDrawer() {
  const [{ user, logout }] = useContext(AppContext)
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <Button component="div" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MenuIcon />
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onOpen={(event) => setAnchorEl(event.currentTarget)}>
        <Box sx={{ width: '240px' }}>
          <Box sx={{ background: '#f4f5fd' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p={4}>
              <Avatar alt={user.name} src={`/api/users/${user.id}/avatar?token=${user.token}`} />
              <Box pt={1}>
                <Typography variant="h5">{user.name}</Typography>
              </Box>
            </Box>
          </Box>

          <Divider />
          <SearchDrawer
            button={
              <ListItem button>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Find companies" />
              </ListItem>
            }
          />
          <Divider />
          <List>
            <Link href={paths.opportunities()} passHref>
              <MaterialLink underline="none" color="inherit">
                <ListItem button>
                  <ListItemIcon>
                    <Icon width="24px" viewBox="0 0 24 24" fill="currentColor" name="opportunity" />
                  </ListItemIcon>
                  <ListItemText primary="Opportunities" />
                </ListItem>
              </MaterialLink>
            </Link>
            <Link href={paths.companies()} passHref>
              <MaterialLink underline="none" color="inherit">
                <ListItem button>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Companies" />
                </ListItem>
              </MaterialLink>
            </Link>
            <Link href={paths.investors()} passHref>
              <MaterialLink underline="none" color="inherit">
                <ListItem button>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Investors" />
                </ListItem>
              </MaterialLink>
            </Link>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              onClick={() => {
                setAnchorEl(null)
                logout()
              }}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  )
}
