import SearchIcon from '@mui/icons-material/Search'
import React, { useState } from 'react'
import { Box, AppBar, Button, SwipeableDrawer, Toolbar } from '@mui/material'
import SearchResultList from 'components/SearchCompanies/SearchResultList'
import SearchField from 'components/SearchCompanies/SearchField'
import SearchProvider from 'contexts/Search'

export default function SearchDrawer(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  return (
    <SearchProvider>
      <Box component="span" onClick={(event) => setAnchorEl(event.currentTarget)}>
        {props.button ? (
          props.button
        ) : (
          <Button component="div" sx={{ color: 'inherit' }}>
            <SearchIcon />
          </Button>
        )}
      </Box>
      <SwipeableDrawer
        anchor="top"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onOpen={(event) => setAnchorEl(event.currentTarget)}>
        <Box sx={{ height: '70vh' }}>
          <AppBar color="inherit" elevation={2}>
            <Box sx={(theme) => ({ margin: theme.spacing(3, 2) })}>
              <SearchField
                onClick={(event) => {
                  setAnchorEl(event.currentTarget)
                }}
                autoFocus
                placeholder="Find companies and opportunities"
              />
            </Box>
          </AppBar>
          <Toolbar sx={(theme) => ({ margin: theme.spacing(3, 2) })} />
          <SearchResultList
            onClick={() => {
              setAnchorEl(null)
            }}
          />
        </Box>
      </SwipeableDrawer>
    </SearchProvider>
  )
}
