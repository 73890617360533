import React from 'react'

const getPath = (name, { fill }) => {
  switch (name) {
    case 'globe':
      return (
        <g transform="matrix(1,0,0,1,-189,-3389.01)">
          <g transform="matrix(1,0,0,1.59074,-5,3165.38)">
            <g transform="matrix(0.0352941,0,0,0.0221872,194,140.58)">
              <path d="M255,0C114.75,0 0,114.75 0,255C0,395.25 114.75,510 255,510C395.25,510 510,395.25 510,255C510,114.75 395.25,0 255,0ZM430.95,153L357,153C349.35,119.85 336.6,91.8 321.3,61.2C367.2,79.05 408,109.65 430.95,153ZM255,51C275.4,81.6 293.25,114.75 303.45,153L206.55,153C216.75,117.3 234.6,81.6 255,51ZM58.65,306C53.55,290.7 51,272.85 51,255C51,237.15 53.55,219.3 58.65,204L145.35,204C142.8,221.85 142.8,237.15 142.8,255C142.8,272.85 145.35,288.15 145.35,306L58.65,306ZM79.05,357L153,357C160.65,390.15 173.4,418.2 188.7,448.8C142.8,430.95 102,400.35 79.05,357ZM153,153L79.05,153C104.55,109.65 142.8,79.05 188.7,61.2C173.4,91.8 160.65,119.85 153,153ZM255,459C234.6,428.4 216.75,395.25 206.55,357L303.45,357C293.25,392.7 275.4,428.4 255,459ZM313.65,306L196.35,306C193.8,288.15 191.25,272.85 191.25,255C191.25,237.15 193.8,221.85 196.35,204L316.2,204C318.75,221.85 321.3,237.15 321.3,255C321.3,272.85 316.2,288.15 313.65,306ZM321.3,448.8C336.6,420.75 349.35,390.151 357,357L430.95,357C408,400.35 367.2,430.95 321.3,448.8ZM367.2,306C369.75,288.15 369.75,272.85 369.75,255C369.75,237.15 367.2,221.85 367.2,204L453.9,204C459,219.3 461.549,237.15 461.549,255C461.549,272.85 458.999,290.7 453.9,306L367.2,306Z" />
            </g>
          </g>
        </g>
      )
    case 'linked-in':
      return (
        <g transform="matrix(1,0,0,1,-311.781,-3389.12)">
          <g transform="matrix(1,0,0,1.59074,-363.336,3164.26)">
            <g transform="matrix(0.0351563,0,0,0.0221006,675.117,141.357)">
              <path d="M437,0L75,0C33.648,0 0,33.648 0,75L0,437C0,478.352 33.648,512 75,512L437,512C478.352,512 512,478.352 512,437L512,75C512,33.648 478.352,0 437,0ZM181,406L121,406L121,196L181,196L181,406ZM181,166L121,166L121,106L181,106L181,166ZM391,406L331,406L331,286C331,269.461 317.539,256 301,256C284.461,256 271,269.461 271,286L271,406L211,406L211,196L271,196L271,207.309C286.719,202.422 296.93,196 316,196C356.691,196.043 391,232.547 391,275.688L391,406Z" />
            </g>
          </g>
        </g>
      )
    case 'twitter':
      return (
        <g transform="matrix(1,0,0,1,-414.403,-3389.12)">
          <g transform="matrix(1,0,0,1.59074,218.603,3136.99)">
            <g transform="matrix(0.0352941,0,0,0.0221872,195.8,158.495)">
              <path d="M459,0L51,0C22.95,0 0,22.95 0,51L0,459C0,487.05 22.95,510 51,510L459,510C487.05,510 510,487.05 510,459L510,51C510,22.95 487.05,0 459,0ZM400.35,186.15C397.8,303.45 323.85,385.05 211.65,390.15C165.75,392.7 132.6,377.4 102,359.55C135.15,364.651 178.5,351.901 201.45,331.5C168.3,328.95 147.9,311.1 137.7,283.05C147.9,285.6 158.1,283.05 165.75,283.05C135.15,272.85 114.75,255 112.2,214.2C119.85,219.3 130.05,221.85 140.25,221.85C117.3,209.1 102,160.65 119.85,130.05C153,165.75 193.8,196.35 260.1,201.45C242.25,130.05 339.151,91.8 377.401,140.25C395.251,137.7 408.001,130.05 420.751,124.95C415.651,142.8 405.451,153 392.701,163.2C405.451,160.65 418.201,158.1 428.401,153C425.85,165.75 413.1,175.95 400.35,186.15Z" />
            </g>
          </g>
        </g>
      )
    case 'facebook':
      return (
        <g transform="matrix(1,0,0,1,-509.313,-3389.12)">
          <g transform="matrix(1,0,0,1.59074,310.183,3109.62)">
            <g transform="matrix(0.0351563,0,0,0.0221006,199.13,175.704)">
              <path d="M437,0L75,0C33.648,0 0,33.648 0,75L0,437C0,478.352 33.648,512 75,512L226,512L226,331L166,331L166,241L226,241L226,180C226,130.371 266.371,90 316,90L407,90L407,180L316,180L316,241L407,241L392,331L316,331L316,512L437,512C478.352,512 512,478.352 512,437L512,75C512,33.648 478.352,0 437,0Z" />
            </g>
          </g>
        </g>
      )
    case 'crunchbase':
      return (
        <g transform="matrix(1,0,0,1,-622.823,-3389.12)">
          <g transform="matrix(1,0,0,1.59074,263.823,3166.67)">
            <g transform="matrix(0.28125,0,0,0.176804,358.859,139.752)">
              <path d="M64.5,8.5C64.5,4.083 60.917,0.5 56.5,0.5L8.5,0.5C4.083,0.5 0.5,4.083 0.5,8.5L0.5,56.5C0.5,60.917 4.083,64.5 8.5,64.5L56.5,64.5C60.917,64.5 64.5,60.917 64.5,56.5L64.5,8.5ZM47.024,21.96L46.122,21.96C43.961,22.118 41.881,22.851 40.098,24.082L40.098,10.362L35.1,10.362L35.1,45.645L40.125,45.645L40.125,44.358C44.491,47.43 50.339,47.34 54.609,44.138C58.879,40.936 60.601,35.345 58.874,30.294C57.147,25.243 52.374,21.878 47.024,21.96ZM24.2,37.225C22.787,40.238 19.462,41.855 16.218,41.109C12.974,40.363 10.693,37.453 10.74,34.125C10.787,30.797 13.14,27.953 16.413,27.298C19.665,26.643 22.961,28.363 24.283,31.406L29.76,31.406C28.287,25.344 22.515,21.339 16.32,22.081C10.125,22.823 5.463,28.077 5.463,34.315C5.495,41.057 11.051,46.586 17.793,46.586C23.44,46.586 28.4,42.706 29.76,37.225L24.2,37.225ZM54.091,35.152L54.091,35.38C54.045,35.671 53.981,35.96 53.899,36.243C53.803,36.55 53.699,36.85 53.557,37.143L53.557,37.243C52.604,39.233 50.774,40.668 48.614,41.118L47.944,41.218L47.794,41.218C47.539,41.232 47.282,41.232 47.027,41.218C46.707,41.217 46.387,41.194 46.069,41.15L45.849,41.15C45.229,41.05 44.626,40.865 44.055,40.602L43.918,40.602C43.343,40.319 42.809,39.96 42.33,39.534C41.85,39.096 41.43,38.594 41.084,38.042C40.914,37.762 40.762,37.468 40.632,37.166C39.818,35.306 39.848,33.182 40.714,31.346C41.772,29.113 43.924,27.589 46.382,27.334C46.624,27.321 46.866,27.321 47.108,27.334C50.878,27.384 53.981,30.438 54.091,34.207C54.112,34.522 54.112,34.837 54.091,35.152ZM47.024,21.96L47.026,21.96L47.024,21.96Z" />
            </g>
          </g>
        </g>
      )
    case 'salesforce':
      return (
        <path
          d="M21.7,11.9c1.7-1.7,4-2.8,6.6-2.8c3.4,0,6.4,1.9,8,4.7c1.4-0.6,2.9-1,4.5-1C47,12.8,52,17.8,52,24
	s-5,11.2-11.2,11.2c-0.8,0-1.5-0.1-2.2-0.2c-1.4,2.5-4.1,4.2-7.2,4.2c-1.3,0-2.5-0.3-3.6-0.8c-1.4,3.3-4.7,5.6-8.6,5.6
	c-4,0-7.5-2.5-8.8-6.1c-0.6,0.1-1.2,0.2-1.8,0.2c-4.8,0-8.7-3.9-8.7-8.7c0-3.2,1.7-6,4.3-7.5c-0.5-1.2-0.8-2.6-0.8-4
	c0-5.5,4.5-10,10.1-10C17,8,19.9,9.5,21.7,11.9"
        />
      )
    case 'opportunity':
      return (
        <path
          fill={fill}
          d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11H5m14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14v1z"
        />
      )
    default:
      return <path />
  }
}

const Icon = ({
  name = '',
  style = {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinejoin: 'round',
    strokeMiterlimit: 2,
  },
  fill = '#000',
  viewBox = '0 0 18 18',
  width = '100%',
  height = '100%',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={name != 'salesforce' ? viewBox : '0 0 52 52'}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    {getPath(name, { fill })}
  </svg>
)

export default Icon
