import React from 'react'
import NextHead from 'next/head'

export default function Head(props) {
  const { title, children } = props
  return (
    <NextHead>
      <meta charSet="utf-8" />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>{`Scenic AMP |  ${title || ''}`}</title>

      <link rel="icon" href="/favicon.ico" />
      {children}
    </NextHead>
  )
}
