import { useState, forwardRef } from 'react'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import Link from 'next/link'
import { styled } from '@mui/material/styles'
import StyledAnchor from './StyledAnchor'

const StyledAnchorDropdownItem = styled(StyledAnchor)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  display: 'block',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
    color: '#000',
  },
}))

const DropdownItem = forwardRef(function DropdownItem({ url, label }, ref) {
  return (
    <li>
      <Link passHref href={url}>
        <StyledAnchorDropdownItem ref={ref}>{label}</StyledAnchorDropdownItem>
      </Link>
    </li>
  )
})

export default forwardRef(function DropdownMenu({ menuText, items = [], className = '' }, ref) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button aria-controls="dropdown-menu" aria-haspopup="true" onClick={handleClick} ref={ref}>
        <Box className={className}>
          <Box sx={{ fontWeight: '600' }}>{menuText}</Box>
        </Box>
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        variant="menu">
        {items.map((item) => {
          return <MenuItem key={item.url} component={DropdownItem} {...item} />
        })}
      </Menu>
    </>
  )
})
