import Link, { LinkProps } from 'next/link'
import dynamic from 'next/dynamic'
import { noop } from 'lodash'
import { Grid, Avatar, Typography, ListItem, ListItemText } from '@mui/material'

const Highlighter = dynamic(() => import('react-highlight-words'), { ssr: false })

type SearchResultItemProps = {
  option: {
    name: string
    legalName?: string
    logoUrl: string
    description: string
    currentStage?: string
  }
  searchValue?: string
  onClick?: () => void
  linkProps: LinkProps
}

export default function SearchResultItem({
  option,
  searchValue,
  onClick = noop,
  linkProps,
}: SearchResultItemProps) {
  return (
    <ListItem
      button
      sx={(theme) => ({ padding: theme.spacing(2), borderBottom: '1px solid #eaeaea' })}
      onClick={() => {
        onClick()
      }}>
      <Link {...linkProps}>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <Avatar
              variant="rounded"
              src={option.logoUrl}
              sx={(theme) => ({ width: theme.spacing(10), height: theme.spacing(10) })}
            />
          </Grid>
          <Grid item xs={12} sm zeroMinWidth>
            <Typography variant="h5" noWrap sx={{ display: 'inline-block' }}>
              <Highlighter
                searchWords={searchValue ? [searchValue] : []}
                textToHighlight={option.name}
              />
            </Typography>
            {option.legalName && option.name.toLowerCase() !== option.legalName && (
              <Typography variant="subtitle1" noWrap sx={{ display: 'inline-block' }} ml={2}>
                <Highlighter
                  searchWords={searchValue ? [searchValue] : []}
                  textToHighlight={`(${option.legalName})`}
                />
              </Typography>
            )}
            {option.currentStage ? (
              <ListItemText primary={`Stage: ${option.currentStage}`} />
            ) : null}
            <Typography
              variant="body2"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}>
              <Highlighter
                searchWords={searchValue ? [searchValue] : []}
                textToHighlight={option.description}
              />
            </Typography>
          </Grid>
        </Grid>
      </Link>
    </ListItem>
  )
}
