import React from 'react'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { Container, Grid, Typography, Divider, Box } from '@mui/material'

export default function Footer(props) {
  const router = useRouter()
  const onLoginPage = _.includes(router.route, 'login')

  return (
    <Container component="footer" maxWidth="lg">
      {onLoginPage ? null : (
        <Box mt={10} mb={3}>
          <Divider />
        </Box>
      )}
      <Box pb={6} mt={3}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" align="center">
              © {new Date().getFullYear()} Scenic Advisement | AMP v{props.buildId}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
